.subtitle {
  font-size: 36px;
  font-weight: 700;
  font-family: Montserrat;
  color: #ffffff;
}

.title {
  font-family: Montserrat;

  font-size: 70px;
  color: #ffffff;
  font-style: normal;
  text-transform: lowercase;
}

.btn-header {
  background-color: #00a8ff;
  border-radius: 6px;
}

.a-footer {
  line-break: strict;
}
